import {
  module as BaseModule
} from 'modujs';
import Swiper from 'swiper/bundle';

// import 'swiper/css/bundle';

export default class extends BaseModule {
  constructor(m) {
    super(m);
  }

  init() {



    const swiper = new Swiper(".c-job-combo-slider__main", {
      autoHeight: true,
      autoplay: true,
      // delay: 3000,
      grabCursor: true,

      pagination: {
        el: ".swiper-pagination",
        dynamicBullets: true,
      },
    });

    const swiperImageInfo = new Swiper(".c-image-info-slider", {
      slidesPerView: 2,
      spaceBetween: 30,
      freeMode: true,
      grabCursor: true,
      enabled:false,
      breakpoints: {
        // when window width is >= 320px
       
        1000: {
          enabled: true,
          lazy: true,
          // pagination: {
          //   el: ".swiper-pagination",
          //   clickable: true,
            
            
          // },
          // navigation: {
          //   nextEl: ".swiper-button-next",
          //   prevEl: ".swiper-button-prev",
          // },
        },
       
      }
    });


    const blurbSwiper = new Swiper(".c-blurb-slider__main", {
      autoHeight: false,
      autoplay: false,
      // delay: 3000,
      grabCursor: true,

      pagination: {
        el: ".swiper-pagination",
        dynamicBullets: false,
           
        clickable: true,
      },

         breakpoints: {
        // when window width is >= 320px
       
        1000: {
          slidesPerView: 2,
          enabled: true,
          spaceBetween: 30,
          lazy: true,
          
        },
    }
    
  });




    toggleItem(document.querySelectorAll('.swiper-control-options > button'));

    var benfitsPage = document.querySelector('.c-benefits');

    if(benfitsPage) {
      mobileBenefits();

    }



    function toggleItem(elem) {
      for (var i = 0; i < elem.length; i++) {
        elem[i].addEventListener("click", function (e) {
          var current = this;
          for (var i = 0; i < elem.length; i++) {
            if (current != elem[i]) {
              elem[i].classList.remove('active');
            } else if (current.classList.contains('active') === true) {
              current.classList.remove('active');
              swiper.autoplay.start();
            } else {
              current.classList.add('active');
              swiper.autoplay.stop();
            }
          }
          e.preventDefault();
        });
      };
    }


    function mobileBenefits() {

      // breakpoint where swiper will be destroyed
      // and switches to a dual-column layout
      const breakpoint = window.matchMedia('(min-width:1000px)');

      // keep track of swiper instances to destroy later
      let mySwiper;

      //////////////////////////////////////////////////////////////////
      //////////////////////////////////////////////////////////////////
      //////////////////////////////////////////////////////////////////

      const breakpointChecker = function () {

        // if larger viewport and multi-row layout needed
        if (breakpoint.matches === true) {

          // clean up old instances and inline styles when available
          if (mySwiper !== undefined) mySwiper.destroy(true, true);

          // or/and do nothing
          return;

          // else if a small viewport and single column layout needed
        } else if (breakpoint.matches === false) {

          // fire small viewport version of swiper
          return enableSwiper();

        }

      };

      //////////////////////////////////////////////////////////////////
      //////////////////////////////////////////////////////////////////
      //////////////////////////////////////////////////////////////////

      const enableSwiper = function () {

        mySwiper = new Swiper('.c-benefits-list-scroll__nav', {

          // loop: true,

          slidesPerView: 'auto',

          centeredSlides: true,

          a11y: true,
          keyboardControl: true,
          grabCursor: true,

          pagination: {
            el: ".c-benefits-list-scroll__nav-pagination",
            clickable: true,
          },
       
        });

      };

      //////////////////////////////////////////////////////////////////
      //////////////////////////////////////////////////////////////////
      //////////////////////////////////////////////////////////////////

      // keep an eye on viewport size changes
      breakpoint.addListener(breakpointChecker);

      // kickstart
      breakpointChecker();

    }









  }


}